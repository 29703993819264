import React from 'react';
import { Filter, ViewTable, Layout, Button, Dialog } from '@/components';
import WebApi from '@/web/api';
import { LibAuthCode } from 'lib/Auth';

export default class StockDetail extends React.PureComponent {

  private table = React.createRef<ViewTable>();

  private queryParams = {}

  state: {
    loading: boolean
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  private queryStockList =() => {
    this.table.current?.reset(this.queryParams);
  }

  public refresh = () => {
    this.table.current?.refresh();
  }

  private columns = [
    // {
    //   title: '库存编号',
    //   dataIndex: 'stockId',
    //   width: 180,
    // },
    {
      title: '商品分类',
      dataIndex: 'categoryName',
      width: 150,
    },
    {
      title: '商品名称',
      dataIndex: 'productName',
      width: 200,
    },
    {
      title: '规格',
      dataIndex: 'skuName',
      width: 300,
    },
    {
      title: '仓库',
      dataIndex: 'repositoryName',
      width: 200,
    },
    {
      title: '存量',
      dataIndex: 'stockCount',
      align: 'right',
      sorter: true,
      width: 120,
    },
    {
      title: '进价',
      align: 'right',
      dataIndex: 'price',
      render: value => value || '--',
      sorter: true,
      width: 120,
    },
    {
      title: '结存金额',
      dataIndex: 'amount',
      align: 'right',
      width: 120,
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      render: value => value || '--',
      width: 200,
    },
    {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updatedAt',
      width: 200,
    }
  ];

  private fields = [
    {
      type: 'category',
    },
    {
      type: 'select',
      field: 'repositoryId',
      name: '仓库',
      width: 180,
      options: async() => {
        const list = await WebApi.repository_list();
        return list.map(i => {
          return {
            name: i.repositoryName,
            value: i.repositoryId
          };
        })
      }
    },
    {
      type: 'select',
      field: 'supplierId',
      name: '供应商',
      width: 180,
      options: async() => {
        const list = await WebApi.supplier_list();
        return list.map(i => {
          return {
            name: i.supplierName,
            value: i.supplierId
          };
        })
      }
    },
    {
      type: 'input',
      name: '搜索',
      field: 'productName',
      placeholder: '商品名称'
    },
  ]

  render() {

    return (
      <Layout.Page height overHidden>
        <Layout.Toolbar title="库存分布">
          <Button.Refresh onClick={this.clickRefresh}/>
        </Layout.Toolbar>
        <Filter
          fields={this.fields as any}
          onChange={this.onFilterChange}
        />

        <ViewTable
          flex1
          ref={this.table}
          columns={this.columns as any}
          fetch={this.fetch}
          actionButtons={[
            {
              type: 'delete',
              disabled: (row) => row.stockCount > 0,
              authCode: LibAuthCode.stock.delete,
              onClick: (row) => {
                Dialog.confirm({
                  content: '确认删除吗？',
                  onOk: () => {
                    WebApi.stock_detail_delete({
                      stockId: row.stockId
                    })
                    .then(() => {
                      showSuccess.delete();
                      this.table.current?.refresh();
                    })
                  }
                });
              },
            }
          ]}
        />
      </Layout.Page>
    )
  }

  private fetch = (data) => {
    return WebApi.stock_detail_paging({...data, ...this.queryParams});
  }

  private onFilterChange = values => {
    this.queryParams = values;
    this.queryStockList();
  }

  private clickRefresh = () => {
    this.table.current?.refresh();
  }
}
